import React, {Component} from 'react'
import Wrapper from '../components/Wrapper';
import { Link } from 'gatsby';

class Index extends Component {
    render() {

        return (
            <Wrapper
                title="Page not found"
            >
                <h1>Page not found</h1>
                <p>The page you requested could not be found. Please visit the <Link to="/">home page</Link> and navigate from there.</p>
            </Wrapper>
        )
    }
}

export default Index
